import styled from 'styled-components';
import { brandConfig } from '../../../../../config/brandConfig';
import { themeMedia } from '../../../../../styles/theme';

export const StyledTitle = styled.p`
  font-size: 30px;
  font-weight: 800;
  font-style: italic;
  font-family: ${brandConfig.fonts.text.h1.family};
  text-align: left;
  margin: 0;
  margin-bottom: 30px;
  text-transform: uppercase;

  ${themeMedia.tablet`
      font-size: 50px;
      margin-bottom: 40px;
  `}
`;

export const StyledText = styled.p`
  margin: 0;
  font-size: 17px;
  text-align: left;
  margin-bottom: 30px;

  ${themeMedia.desktop`
      margin-bottom: 40px;
  `}
`

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 8rem;
  margin: 2.5rem 0;

  ${themeMedia.desktop`
     gap: 2rem;
  `}
`

export const StyledBottomDiv = styled.div`
 align-items: center;
 justify-content: center;
 display: flex;
 flex-direction: column;

 position: absolute;
 bottom: 30px;
 left: 0;
 right: 0;

  ${themeMedia.tablet`
    position: static;
  `}
`

export const StyledWarningWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 1rem;

  ${themeMedia.desktop`
     gap: 20px;
  `}
`

export const StyledImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  
  img {
    height: 28px;
  }

  ${themeMedia.desktop`
    gap: 10px;
    img {
      height: 32px;
    }
  `}
`

export const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  p {
    font-weight: bold;
    line-height: 12px;
    font-size: 12px;
    margin: 0;
  }

  ${themeMedia.desktop`
    p {
      line-height: 15px;
      font-size: 15px;
    }
  `}

`