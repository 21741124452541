import { useContext } from 'react'
import { doSocialLogin, doValidateSocialEmail } from '../adapters/auth'
import { AuthContext } from '../context/authProvider'
import { useReCaptcha } from './useReCaptcha'
import { removeCpfMask } from '../helpers/mask'

export const useSocialSignIn = () => {
  const { loginUser } = useContext(AuthContext)

  const recaptchValidateSocialEmail = async ({
    email,
    national_id,
    language_code,
    recaptchaToken
  }) => {
    return await doValidateSocialEmail({
      email,
      national_id: removeCpfMask(national_id),
      language_code,
      recaptcha_token: recaptchaToken
    })
  }

  const validateSocialEmail = useReCaptcha(
    recaptchValidateSocialEmail, 
    'EMAIL_VERIFICATION'
  )

  const socialSignIn = async ({
    code,
    type,
    onError
  }) => {
    const response = await doSocialLogin({
      code,
      type
    })

    const IS_REGISTER_FLOW = 
      response?.error?.messageCode === 260 && 
      response?.error?.data 

    if (IS_REGISTER_FLOW) {
      return {
        flow: 'REGISTER',
        data: response?.error?.data
      } 
    }

    const IS_LOGIN_FLOW = 
      !response?.data?.messageCode && 
      response?.data?.access_token
    
    if (IS_LOGIN_FLOW) {
      const { ok } = await loginUser({ loginData: response })

      if (ok) {
        return {
          flow: 'LOGIN',
        }
      }
    }

    if (response.error) {
      onError && onError(response)
      return
    }
  }

  return {
    socialSignIn,
    validateSocialEmail
  }
}
