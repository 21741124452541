import React from 'react'
import { StyledImageWrapper, StyledText, StyledTextWrapper, StyledTitle, StyledWarningWrapper, StyledBottomDiv, StyledWrapper } from './styles'
import { useTranslation } from '../../../../../context/translationProvider'
import GoogleSignIn from '../../../../molecules/GoogleSignIn'
import { Link } from 'gatsby'
import { FB_USER_QUERY_PARAM } from '../../../../../utils/constants'
import { isFbUser } from '../../../../../helpers/urlHelper'
import useSlugs from '../../../../../hooks/useSlugs'
import { useModalContext } from '../../../../../context/modalProvider'
import AgeLimitIcon from '../../../../../images/icon_age_limit.svg'
import ResponsibleGamblingIcon from '../../../../../images/icon_responsible_gamling.svg'
import { toast } from '../../../../atoms'
import { treatLoginErrors } from '../../../../../utils/treatLogginErrors'
import { Button } from '../../../../atoms/button'
import { Divider } from '../../../../atoms/divider'
import { useEventTracking } from '../../../../../context/eventTrackingProvider'

export const RegisterLobby = ({ onCompleteStep, onSocialAuthSuccess }) => {
  const { translate } = useTranslation()
  const { trackEvent, EVENT_NAMES } = useEventTracking()

  const [loginSlug] = useSlugs(['login'])
  const fbQuery = isFbUser() ? FB_USER_QUERY_PARAM : ''
  const loginUrl = `${loginSlug}${fbQuery}`
  const { close } = useModalContext()

  const onLogin = () => {
    if (location.pathname.includes(`/${loginSlug}/`)) close()
  }

  const treatCommonErrors = (response) => {
    const errorMessage = treatLoginErrors(response.error, translate)

    toast.error(errorMessage)
  }

  const onSelectEmail = () => {
    trackEvent(EVENT_NAMES.REGISTER_WITH_EMAIL, {}, true)
    onCompleteStep([])
  }

  return (
    <StyledWrapper>
      <div>
        <StyledTitle>
          {translate('register.lobby.title')}
        </StyledTitle>
        <StyledText>
          {translate('register.lobby.description')}
        </StyledText>

        <GoogleSignIn 
          onAuthSuccess={onSocialAuthSuccess} 
          onAuthError={(error) => treatCommonErrors(error)}
          buttonLabel={translate('register.lobby.googleButton')}
          eventName={EVENT_NAMES.REGISTER_WITH_GOOGLE}
        />

        <Divider />

        <Button
          expand
          dark
          bordered
          onClick={onSelectEmail}
        >
          {translate('register.lobby.emailButton')}
        </Button>
      </div>

      <StyledBottomDiv>
          <StyledText>
          {translate('register.alreadyHaveAccount')}{' '}
            <Link to={`/${loginUrl}`} onClick={onLogin}>
              {translate('register.login')}
            </Link>
          </StyledText>

          <StyledWarningWrapper>
          <StyledImageWrapper>
            <img src={AgeLimitIcon} />
            <img src={ResponsibleGamblingIcon} />
          </StyledImageWrapper>
          <StyledTextWrapper>
            <StyledText>
              {translate('register.topBanner.ageLimit')}
            </StyledText>
            <StyledText>
              {translate('register.topBanner.responsibleGambling')}
            </StyledText>
          </StyledTextWrapper>
        </StyledWarningWrapper>
      </StyledBottomDiv>
    </StyledWrapper>
  )
}
