import React from 'react'
import PropTypes from 'prop-types'

import { FormBanner } from '../formBanner'
import { RegisterContent } from '../registerContent'
import { Button } from '../../atoms/button'
import { ModalHeader } from '../../atoms/modalHeader'
import { ResponsiveContainer } from '../../atoms/responsiveContainer'
import { Text, Title } from '../../atoms/text'
import {
  useRegistrationSteps,
} from '../../../hooks/useRegistrationSteps'
import { useState, useEffect } from 'react'
import {
  StyledAnimatedWrapper,
  StyledButtonWrapper,
  StyledCancelLink,
  StyledCloseButton,
  StyledLeavingContent,
  StyledLeavingContentMobile,
  StyledLeavingText,
  StyledRegisterModalWrapper,
} from './styles'
import { useModalContext } from '../../../context/modalProvider'
import { useBannersApiData } from '../../../hooks/graphqlStaticQuery/useBannersApiData'
import { useTranslation } from '../../../context/translationProvider'
import { themeSizes } from '../../../styles/theme'
import { EnvConfig } from '../../../config/EnvConfig'
import { Script } from 'gatsby'
import useFeatures from '../../../hooks/useFeatures'
import { useEventTracking } from '../../../context/eventTrackingProvider'
import { REGISTER_MODAL_FLOWS } from '../../../utils/constants'

const RegisterModal = (props) => {
  const { translate } = useTranslation()
  const { close } = useModalContext()
  const registerCloseModal = useFeatures('registerCloseModal')
  const { 
    flow =  REGISTER_MODAL_FLOWS.REGISTER,
    userData = {},
    defaultSocialSignInData = null,
  } = props?.data || {}
  const { 
    formStep, 
    goToNextStep, 
    goBack, 
    isLastStep,
    REGISTRATION_STEP_IDS,
    REGISTRATION_STEPS,
    setIsSocialSignIn,
    isSocialSignIn,
  } = useRegistrationSteps({ flow })
  const [isLeaving, setIsLeaving] = useState(false)
  const [slides] = useBannersApiData(['registration'])
  const { trackEvent, EVENT_NAMES, EVENT_LOCATIONS } = useEventTracking()

  const onContinue = () => {
    setIsLeaving(false)
  }

  const onCloseButton = () => {
    if (isLeaving || !registerCloseModal) {
      close()
      setIsLeaving(false)
    } else {
      setIsLeaving(true)
    }
    window.dataLayer?.push({ event: `close_register_modal_step_${formStep}` })
  }

  useEffect(() => {
    if (isLeaving) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'registrationw_closing_confirm_page_viewed',
      })
      const stepName = REGISTRATION_STEPS?.[formStep]?.id
      trackEvent(EVENT_NAMES.SCREEN_LOADED, {
        screen_name: EVENT_LOCATIONS.REGISTER_QUIT_CONFIRMATION,
        step: stepName || formStep,
      })
    }
  }, [isLeaving])

  const createButtons = () => (
    <StyledButtonWrapper>
      <Button onClick={onContinue}>
        {translate('register.modal.continue')}
      </Button>
      <StyledCancelLink onClick={onCloseButton}>
        {translate('register.modal.cancel')}
      </StyledCancelLink>
    </StyledButtonWrapper>
  )

  const createMobileExitScreen = () => (
    <StyledLeavingContentMobile>
      <Title level={2}>{translate('register.modal.areYouSure')}</Title>
      <FormBanner slides={slides} />
      <Text>
        <span
          dangerouslySetInnerHTML={{
            __html: translate('register.modal.signupLeavingText'),
          }}
        />
      </Text>
      {createButtons()}
    </StyledLeavingContentMobile>
  )

  const createDesktopExitScreen = () => (
    <StyledLeavingContent>
      {/* <FormBanner slides={slides} /> */}
      <StyledLeavingText>
        <Title level={2}>{translate('register.modal.areYouSure')}</Title>
        <Text>
          <span
            dangerouslySetInnerHTML={{
              __html: translate('register.modal.signupLeavingText'),
            }}
          />
        </Text>
        {createButtons()}
      </StyledLeavingText>
    </StyledLeavingContent>
  )

  const getPrepopulatedInputs = () => {
    if (props?.prepopulatedInputs) {
      return { national_id: props?.prepopulatedInputs?.idToRegister }
    }
    return undefined
  }

  const createContent = () => {
    if (!registerCloseModal) {
      return (
        <RegisterContent
          prepopulatedInputs={getPrepopulatedInputs()}
          goToNextStep={goToNextStep}
          formStep={formStep}
          goBack={goBack}
          isLastStep={isLastStep}
          layout="modal"
          flow={flow}
          REGISTRATION_STEP_IDS={REGISTRATION_STEP_IDS}
          REGISTRATION_STEPS={REGISTRATION_STEPS}
          userData={userData}
          setIsSocialSignIn={setIsSocialSignIn}
          isSocialSignIn={isSocialSignIn}
          defaultSocialSignInData={defaultSocialSignInData}
        />
      )
    }

    return (
      <>
        <StyledAnimatedWrapper hide={!isLeaving}>
          <ResponsiveContainer
            sizeNumber={themeSizes.tablet}
            render={createMobileExitScreen}
            renderElse={createDesktopExitScreen}
          />
        </StyledAnimatedWrapper>
        <StyledAnimatedWrapper hide={isLeaving}>
          <RegisterContent
            prepopulatedInputs={getPrepopulatedInputs()}
            goToNextStep={goToNextStep}
            formStep={formStep}
            goBack={goBack}
            isLastStep={isLastStep}
            layout="modal"
            closeRegisterModal={close}
            flow={flow}
            REGISTRATION_STEP_IDS={REGISTRATION_STEP_IDS}
            REGISTRATION_STEPS={REGISTRATION_STEPS}
            userData={userData}
            setIsSocialSignIn={setIsSocialSignIn}
            isSocialSignIn={isSocialSignIn}
            defaultSocialSignInData={defaultSocialSignInData}
          />
        </StyledAnimatedWrapper>
      </>
    )
  }

  return (
    <div>
      <StyledRegisterModalWrapper id="register-modal">
        <ResponsiveContainer
          sizeNumber={themeSizes.tablet}
          render={() => (
            <ModalHeader
              closeModal={onCloseButton}
              title={translate('register.title')}
              onBack={goBack}
              hideBack={isLeaving || formStep == 0}
              hideClose={isLeaving}
              stickHeaderToTop
            />
          )}
        />
        {createContent()}
        <StyledCloseButton onClick={onCloseButton} />

        <Script
          src={`https://www.google.com/recaptcha/enterprise.js?render=${EnvConfig.GATSBY_RECAPTCHA_KEY}`}
        />
      </StyledRegisterModalWrapper>
    </div>
  )
}

RegisterModal.propTypes = {
  prepopulatedInputs: PropTypes.shape({
    idToRegister: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    flow: PropTypes.string,
    userData: PropTypes.shape({
      email: PropTypes.string,
      password: PropTypes.string,
      nationalId: PropTypes.string,
      phone: PropTypes.string,
      phonePrefix: PropTypes.string,
      state: PropTypes.string,
      city: PropTypes.string,
      address: PropTypes.string,
      zipCode: PropTypes.string
    })
  }),
  defaultSocialSignInData: PropTypes.shape({
    email: PropTypes.string,
    idToken: PropTypes.string,
    type: PropTypes.string,
  }),
}

export { RegisterModal }
