import styled from 'styled-components'
import { themeStyles, themeMedia } from '../../../styles/theme'
import { Button } from '../../atoms/button'

export const StyledCookieMessageWrapper = styled.div`
  vertical-align: middle;
  z-index: 1000000;
  position: fixed;
  bottom: 10px;
  right: 0;
  left: 0;
  width: calc(100% - 20px);
  background-color: ${themeStyles.colors.darkGreys[5].main};
  display: flex;
  padding: 0.7rem;
  margin: 0 auto;
  margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};
  flex-direction: column;
  box-sizing: border-box;
  line-height: 1.2;
  gap: 10px;
  border-radius: 6px;

  ${themeMedia.desktop`
    width: 75%;
    bottom: 20px;
    flex-direction: row;
    text-align: left;
  `}
`

export const StyledNoticeText = styled.div`
  color: ${themeStyles.colors.white.main};
  width: 100%;
  font-size: 14px;
  align-items: center;
  margin-bottom: 8px;

  p {
    margin: 0;
  }

  a {
    display: contents;
    color: ${themeStyles.colors.accentColors.secondary.main};
    text-decoration: underline;
    cursor: pointer;
    margin-left: 4px;
  }

  ${themeMedia.desktop`
    width: 100%;
    font-size: 17px;
    display: inline-flex;
    align-items: center;
    text-align: left;
    margin-bottom: 0;
  `}
`

export const StyledButton = styled(Button)`
  height: 36px;
  ${themeMedia.desktop`
    height: 50px;
  `}
`

export const CTAButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
`
