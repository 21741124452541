import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { FieldNationalId } from '../../../../molecules/fieldNationalId'
import { Field } from '../../../../molecules/field'
import { FieldPassword } from '../../../../molecules/fieldPassword'
import { Button } from '../../../../atoms/button'
import { Text } from '../../../../atoms/text'
import { passwordRegex } from '../../../../../utils/passwordValidation'
import { emailRegex } from '../../../../../utils/emailValidation'
import { doEmailCheck } from '../../../../../adapters/auth'
import { 
  StyledEmailAndPasswordStep, 
  StyledErrorMessageWrapper 
} from '../../styles'
import { Link } from 'gatsby'
import useSlugs from '../../../../../hooks/useSlugs'
import { useModalContext } from '../../../../../context/modalProvider'
import { formSubmitIsDisabled } from '../../../../../utils/formUtils'
import useDeviceDetect from '../../../../../hooks/useDeviceDetect'
import { useTranslation } from '../../../../../context/translationProvider'
import { Turnstile, useTurnstileOn } from '../../../../../hooks/useTurnstile'
import { brandConfig } from '../../../../../config/brandConfig'
import { isFbUser } from '../../../../../helpers/urlHelper'
import {
  FB_USER_QUERY_PARAM,
  REGISTER_MODAL_FLOWS
} from '../../../../../utils/constants'
import useCAFPolling from '../../customComponents/hooks/useCAFPolling'
import {
  RegistrationCheckmarks
} from '../../../../molecules/registrationCheckmarks'
import { FieldDropdown } from '../../../../molecules/fieldDropdown'
import { useEventTracking } from '../../../../../context/eventTrackingProvider'
import { StyledText, StyledTitle } from './styles'
import { If } from '@techmobilt/ui-components'
import { Message } from '../../../../atoms/message'
import { delay } from '../../../../../helpers/delay'
import { useSocialSignIn } from '../../../../../hooks/useSocialSignIn'

const EmailAndPasswordStep = (props) => {
  const {
    formProperties,
    onCompleteStep,
    country,
    setCafData,
    checkNationalId,
    onInputFocus,
    onInputBlur,
    prepopulatedInputs,
    registerTime,
    setToken,
    setStepsDone,
    nationalities,
    REGISTRATION_STEP_IDS,
    REGISTRATION_STEPS,
    flow = 'REGISTER',
    isSocialSignIn,
    socialSignInData = {}
  } = props
  const { checkCaf } = useCAFPolling({
    formProperties,
    setStepsDone,
    setCafData,
    REGISTRATION_STEP_IDS,
    REGISTRATION_STEPS,
  })
  const [loginSlug] = useSlugs(['login'])
  const [loading, setLoading] = useState(false)
  const fbQuery = isFbUser() ? FB_USER_QUERY_PARAM : ''
  const loginUrl = `${loginSlug}${fbQuery}`
  const { close } = useModalContext()
  const { isMobile } = useDeviceDetect()
  const { translate } = useTranslation()
  const [stepTimer] = useState(new Date())
  const [initialValues, setInitialValues] = useState({})
  const [isCaptchaPassed, setIsCaptchaPassed] = useState(false)
  const { captchaTurnstileOn } = useTurnstileOn()
  const { trackEvent, EVENT_NAMES, EVENT_LOCATIONS } = useEventTracking()
  const [errorMessage, setErrorMessage] = useState('')

  const {
    register,
    getValues,
    setError,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = formProperties

  const onCaptchaVerified = useCallback((data) => {
    setToken(data)
    setIsCaptchaPassed(true)
  }, [])

  const onCaptchaExpired = useCallback(() => {
    setToken(null)
    setIsCaptchaPassed(false)
  }, [])

  useEffect(() => {
    const fields = ['national_id', 'email']
    
    if (!isSocialSignIn) {
      fields.push('password')
    }

    watch(fields)
  }, [isSocialSignIn])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'registrationw_cpf_page_viewed',
        nationality: country?.code,
        register_method: socialSignInData?.type?.toLowerCase() || 'email',
      })
    }
  }, [country?.code])

  // Function to handle the simulated click
  const handleClick = (x, y) => {
    const element = document.elementFromPoint(x, y)
    // Check if the clicked position is really a button
    if (element && element.tagName === 'BUTTON') {
      element.click()
    }
  }

  // Function to capture click events and their positions
  const captureClick = (event) => {
    const { clientX, clientY } = event
    // Call handleClick if not a button to avoid duplicated cliks
    if (event.target.tagName !== 'BUTTON') {
      handleClick(clientX, clientY)
    }
  }

  useEffect(() => {
    const init = async () => {
      if (prepopulatedInputs?.['national_id']) {
        // Set the prepopulated national_id value and check if it's a valid CPF
        setValue('national_id', prepopulatedInputs['national_id'])
        await delay(100)
        trigger('national_id')
        document.querySelector('#email')?.focus()
      }
    }
    init()
    // Add the click event listener to avoid double clicking
    window.addEventListener('click', captureClick)
    // Cleanup the event listener when the component unmounts
    return () => window.removeEventListener('click', captureClick)
  }, [])

  const checkEmail = async () => {
    const stepNum = REGISTRATION_STEPS.findIndex(
      (step) => step.id === REGISTRATION_STEP_IDS.CONFIRM_EMAIL
    )
    setStepsDone((state) => ({ ...state, [stepNum]: false }))

    const emailResponse = await doEmailCheck(getValues('email'))
    if (!emailResponse.ok) {
      setError('email', {
        message: translate(
          'register.error.the_username_already_taken_please_select_another'
        ),
      })
      return false
    }
    return true
  }

  const triggerStepTimer = () => {
    const actualTime = new Date()

    registerTime(
      'first_registration_step',
      actualTime.getTime() - stepTimer.getTime()
    )
  }

  const { validateSocialEmail } = useSocialSignIn()

  const aditionalChecks = async () => {
    setLoading(true)
    setErrorMessage('')
    const isSameEmail = initialValues.email === getValues('email')
    const isEmailGood = isSameEmail || (await checkEmail())
    if (isEmailGood) {
      if (!checkNationalId) {
        setLoading(false)

        triggerStepTimer()
        return true
      }
      const isSameCpf = initialValues.national_id === getValues('national_id')
      const isSameNationality =
        initialValues.nationality === getValues('nationality')
      const isCpfGood = (isSameCpf && isSameNationality) || (await checkCaf())
      setLoading(false)

      if (isCpfGood) triggerStepTimer()

        if (isSocialSignIn) {
          const { ok } = await validateSocialEmail({
            email: getValues('email'),
            national_id: getValues('national_id'),
            language_code: getValues('languageCode'),
          })

          if (!ok) {
            setErrorMessage(translate('common.unexpectedError'))
            setLoading(false)
            return false
          }
        }

      return isCpfGood
    }
    setLoading(false)
    return false
  }

  const onLogin = () => {
    if (location.pathname.includes(`/${loginSlug}/`)) close()
  }

  const getFieldsToWatch = () => {
    const defaultFields = [
      'tandc',
      'privacyPolicy',
      'belongHere',
    ]

    if (flow === REGISTER_MODAL_FLOWS.LOGIN) {
      return defaultFields
    }

    if (!isSocialSignIn) {
      defaultFields.push('password')
    }

    if (flow === REGISTER_MODAL_FLOWS.FORGOT_PASSWORD) {
      return defaultFields
    }

    defaultFields.push('email')
    defaultFields.push('national_id')
    defaultFields.push('nationality')

    return defaultFields
  }

  const fieldsToWatch = getFieldsToWatch()

  const completeFirstStep = () => {
    if (!captchaTurnstileOn || isCaptchaPassed) {
      onCompleteStep(fieldsToWatch, aditionalChecks)
      trackEvent(EVENT_NAMES.PERSONAL_DATA_COMPLETED, {
        location: EVENT_LOCATIONS.REGISTER_START,
        promo_opt_in: getValues('marketing'),
        register_method: socialSignInData?.type?.toLowerCase() || 'email',
      }, true)
    }
  }

  useEffect(() => {
    setInitialValues({
      email: getValues('email'),
      national_id: getValues('national_id'),
      nationality: getValues('nationality'),
    })

    watch(['tandc', 'privacyPolicy', 'belongHere'])
  }, [])

  const IS_LOGIN_FLOW = flow === REGISTER_MODAL_FLOWS.LOGIN
  const IS_FORGOT_PASSWORD_FLOW = flow === REGISTER_MODAL_FLOWS.FORGOT_PASSWORD
  const IS_REGISTER_FLOW = flow === REGISTER_MODAL_FLOWS.REGISTER

  const renderContent = () => {
    if (IS_LOGIN_FLOW) {
      return (
        <>
          <StyledTitle>
            {translate('register.nonMigrated.login.title')}
          </StyledTitle>
          <StyledText>
            {translate('register.nonMigrated.login.description')}
          </StyledText>
        </>
      )
    }
    
    if (IS_FORGOT_PASSWORD_FLOW) {
      return (
        <>
          <StyledTitle>
            {translate('register.nonMigrated.forgotPassword.title')}
          </StyledTitle>
          <StyledText>
            {translate('register.nonMigrated.forgotPassword.description')}
          </StyledText>

          <FieldPassword
            label={translate('account.profile.newPassword')}
            errors={errors}
            autoCompleteOff
            description={translate('common.passwordHint')}
            showTipsOnFocus
            scrollOnFocus={isMobile}
            onFocus={onInputFocus}
            onBlur={() => onInputBlur('password_input_timer')}
            defaultValue={getValues('password')}
            formProperties={register('password', {
              required: true,
              length: {
                value: 8,
                message: translate('common.passwordHint'),
              },
              pattern: {
                value: passwordRegex,
                message: translate('common.passwordHint'),
              },
            })}
          />
        </>
      )
    }

    return (
      <>
        <FieldNationalId
          formProperties={formProperties}
          country={country}
          description={checkNationalId ? translate('register.enterCPF') : ''}
          onFocus={onInputFocus}
          onBlur={() => onInputBlur('national_id_input_timer')}
        />

        <Field
          label={translate('userProfile.email')}
          description={translate('userProfile.emailHint')}
          maxLength="100"
          type="email"
          autoCompleteOff
          showErrorMsg
          errors={errors}
          onFocus={onInputFocus}
          onBlur={() => onInputBlur('email_input_timer')}
          disabled={isSocialSignIn}
          formProperties={register('email', {
            required: true,
            pattern: {
              value: emailRegex,
              message: translate('register.error.incorrectEmailFormat'),
            },
          })}
        />

        <If
          condition={!isSocialSignIn}
          render={() => (
            <FieldPassword
              errors={errors}
              autoCompleteOff
              description={translate('common.passwordHint')}
              showTipsOnFocus
              scrollOnFocus={isMobile}
              onFocus={onInputFocus}
              onBlur={() => onInputBlur('password_input_timer')}
              defaultValue={getValues('password')}
              formProperties={register('password', {
                required: true,
                length: {
                  value: 8,
                  message: translate('common.passwordHint'),
                },
                pattern: {
                  value: passwordRegex,
                  message: translate('common.passwordHint'),
                },
              })}
            />
          )}
        />

        <FieldDropdown
          defaultValue={' '}
          emptyValue={' '}
          fieldValue={getValues('nationality')}
          autoCompleteOff
          errors={errors}
          label={translate('userProfile.nationality')}
          removeFirstOption={!!getValues('nationality')}
          onFocus={onInputFocus}
          formProperties={register('nationality', { required: true })}
          onBlur={() => onInputBlur('nationality_input_timer')}
          values={nationalities.map((nationality, i) => (
            <option value={nationality.id} key={`nationality-${i}`}>
              {nationality.name}
            </option>
          ))}
        />
      </>
    )
  }

  return (
    <StyledEmailAndPasswordStep>
      {renderContent()}

      <RegistrationCheckmarks formProperties={formProperties} />

      <Turnstile 
        action="REGISTRATION"
        onCaptchaVerified={onCaptchaVerified}
        onCaptchaExpired={onCaptchaExpired}
      />

      <Button
        id="nextBtn1"
        expand
        loading={loading}
        type="button"
        style={{
          fontSize: brandConfig.fonts.text.button.size.desktop,
        }}
        disabled={
          formSubmitIsDisabled(
            fieldsToWatch,
            getValues,
            errors
          ) ||
          (!isCaptchaPassed && captchaTurnstileOn)
        }
        onClick={completeFirstStep}
      >
        {translate('register.next')}
      </Button>

      <If 
        condition={errors?.national_id?.message || errorMessage}
        render={() => (
          <StyledErrorMessageWrapper>
            <Message
              error
              text={errors?.national_id?.message || errorMessage}
              id="errorMessage" 
            />
          </StyledErrorMessageWrapper>
        )}
      />

      <If 
        condition={IS_REGISTER_FLOW}
        render={() => (
          <Text textAlign="center">
            {translate('register.alreadyHaveAccount')}{' '}
            <Link to={`/${loginUrl}`} onClick={onLogin}>
              {translate('register.login')}
            </Link>
          </Text>
        )}
      />
    </StyledEmailAndPasswordStep>
  )
}

EmailAndPasswordStep.propTypes = {
  formProperties: PropTypes.shape({
    register: PropTypes.func,
    getValues: PropTypes.func,
    setError: PropTypes.func,
    setValue: PropTypes.func,
    watch: PropTypes.func,
    formState: PropTypes.shape({ errors: PropTypes.object }),
  }),
  checkNationalId: PropTypes.bool,
  onCompleteStep: PropTypes.func,
  setCafData: PropTypes.func,
  country: PropTypes.object,
  nationalities: PropTypes.array,
  onInputFocus: PropTypes.func,
  onInputBlur: PropTypes.func,
  prepopulatedInputs: PropTypes.object,
  registerTime: PropTypes.func,
  setToken: PropTypes.func,
  setStepsDone: PropTypes.func,
  flow: PropTypes.string,
  REGISTRATION_STEP_IDS: PropTypes.object,
  REGISTRATION_STEPS: PropTypes.array,
  isSocialSignIn: PropTypes.bool,
  socialSignInData: PropTypes.object,
}

export { EmailAndPasswordStep }
