import mixpanel from 'mixpanel-browser'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { isBrowser } from '../utils/generic'
import { EVENT_NAMES, EVENT_LOCATIONS } from '../utils/constants'
import { getUserProfileProperties } from '../utils/mixpanelUtils'
import { useGrowthbookFeature } from '../hooks/useGrowthbook'
import { pushDataLayer } from '../utils/pushDataLayer'

const EventTrackingContext = createContext()

export const EventTrackingProvider = ({ children }) => {
  const [mixpanelLoaded, setMixpanelLoaded] = useState()
  const eventTrackingEnabled = useGrowthbookFeature(
    'fe_igp_event_tracking_enabled'
  )

  useEffect(() => {
    if (!eventTrackingEnabled) return

    mixpanel.init(process.env.GATSBY_MIXPANEL_PROJECT_TOKEN, {
      debug: process.env.GATSBY_MIXPANEL_ENV === 'dev',
      track_pageview: process.env.GATSBY_MIXPANEL_TRACK_PAGEVIEW === 'true',
      persistence: 'localStorage',
      loaded: () => setMixpanelLoaded(true),
    })
  }, [eventTrackingEnabled])

  const value = { eventTrackingEnabled, mixpanelLoaded }

  return (
    <EventTrackingContext.Provider value={value}>
      {children}
    </EventTrackingContext.Provider>
  )
}

export const useEventTracking = () => {
  const context = useContext(EventTrackingContext)

  const { eventTrackingEnabled, mixpanelLoaded } = context || {}

  const trackEvent = (eventName, properties = {}, sendGAEvent = false) => {
    if (!eventTrackingEnabled) return
    if (!mixpanelLoaded) return
    if (!isBrowser() || !eventName) return

    const extendedProperties = {
      ...properties,
      env: process.env.GATSBY_MIXPANEL_ENV,
    }

    mixpanel.track(eventName, extendedProperties)

    if (sendGAEvent) {
      pushDataLayer({
        eventName,
        data: extendedProperties
      })
    }
  }

  const identifyUser = (user) => {
    if (!eventTrackingEnabled) return
    if (!mixpanelLoaded) return
    if (!user?.id) return

    const userProfileProperties = getUserProfileProperties(user)
    mixpanel.people.set(userProfileProperties)

    mixpanel.identify(user?.id.toString())
  }

  const deidentifyUser = () => {
    if (!eventTrackingEnabled) return
    if (!mixpanelLoaded) return

    mixpanel.reset()
  }

  return {
    trackEvent,
    identifyUser,
    deidentifyUser,
    EVENT_NAMES,
    EVENT_LOCATIONS,
  }
}
