import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from '../../../../../context/translationProvider'
import { Field } from '../../../../molecules/field'
import { Text } from '../../../../atoms/text'
import {
  StyledLoaderWrapper,
  StyledWrapper
} from './styles'
import { FieldDropdown } from '../../../../molecules/fieldDropdown'
import { Button } from '../../../../atoms/button'
import { brandConfig } from '../../../../../config/brandConfig'
import { formSubmitIsDisabled } from '../../../../../utils/formUtils'
import { getCEPData } from '../../../../../adapters/cep'
import { useLoading } from '../../../../../hooks/useLoading'
import { If } from '@techmobilt/ui-components'
import { SpinnerLoader } from '../../../../atoms/loader'
import PropTypes from 'prop-types'
import { Turnstile, useTurnstileOn } from '../../../../../hooks/useTurnstile'
import { useEventTracking } from '../../../../../context/eventTrackingProvider'
import { stringNormalize } from '../../../../../utils/stringNormalize'
import { Divider } from '../../../../atoms/divider'

const DEFAULT_FIELDS_ORDER = [
  'STATE',
  'CITY',
  'ADDRESS',
  'ADDRESS_NUMBER',
]

export const CepAddressStep = (props) => {
  const {
    formProperties,
    onCompleteStep,
    data,
    loading,
    registerTime,
    onInputFocus,
    onInputBlur,
    setToken,
    defaultData,
    clearResponseError,
  } = props

  const { regions, cities } = data

  const {
    register,
    getValues,
    setValue,
    formState,
    clearErrors,
    setError,
    resetField,
  } = formProperties
  const { errors } = formState

  const { captchaTurnstileOn } = useTurnstileOn()
  const [stepTimer] = useState(new Date())
  const { translate } = useTranslation()
  const [fieldsOrder, setFieldsOrder] = useState(DEFAULT_FIELDS_ORDER)
  const [isValidCEP, setIsValidCEP] = useState(false)
  const { isLoading, startLoading, stopLoading} = useLoading()
  const [availableCities, setAvailableCities] = useState([])
  const [isCaptchaPassed, setIsCaptchaPassed] = useState(false)
  const [cepValue, setCepValue] = useState('')
  const { trackEvent, EVENT_NAMES, EVENT_LOCATIONS } = useEventTracking()

  const handleDefaultData = () => {
    if (defaultData?.state) {
      const state = findStateByName(defaultData.state)
      setValue('state', state?.name)
      handleStateChange({ target: { value: defaultData.state } })
    }
  }

  useEffect(() => {
    handleDefaultData()
  }, [defaultData])

  const onCaptchaVerified = useCallback((data) => {
    setToken(data)
    setIsCaptchaPassed(true)
  }, [])

  const onCaptchaExpired = useCallback(() => {
    setToken(null)
    setIsCaptchaPassed(false)
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'registrationw_address_page_viewed',
      })
      trackEvent(EVENT_NAMES.SCREEN_LOADED, {
        screen_name: EVENT_LOCATIONS.REGISTER_ADDRESS,
      })
    }

    const state = getValues('state')
    if (state) {
      handleStateChange({ target: { value: state } })
    }
  }, [])

  const findStateByName = (stateName) => {
    return regions.find((state) => stringNormalize(state.name) === stringNormalize(stateName))
  }

  const findCityByName = (cityName) => {
    return availableCities.find((city) => stringNormalize(city.name) === stringNormalize(cityName))
  }

  const filterCities = (regionId) => {
    return cities.filter((city) => city.regionId === regionId)
  }

  const handleStateChange = ({ target: { value }}) => {
    const findState = findStateByName(value)
    const filteredCities = filterCities(findState?.id)
    setAvailableCities(filteredCities)

    setValue('city', filteredCities?.[0]?.name)
   
    if (defaultData.city) {
      const city = findCityByName(defaultData?.city)
      if (city) {
        setValue('city', city?.name)
      }
    }

    if (defaultData?.address) {
      setValue('address', defaultData?.address)
    }
  }

  const createDropdown = ({ values, fieldName, onChange }) => {
    return (
      <FieldDropdown
        key={`dropdown-${fieldName}`}
        autoCompleteOff
        errors={errors}
        label={translate(`userProfile.${fieldName}`)}
        fieldValue={getValues(fieldName)}
        onChange={(e) => {
          setValue(fieldName, e.target.value)
          clearErrors(fieldName)
          clearResponseError()
          if (onChange) onChange(e)
        }}
        formProperties={register(fieldName, {
          required: values?.length > 0,
        })}
        values={values?.map((row, i) => (
          <option key={`${fieldName}-${i}`} value={row.name}>
            {row.name}
          </option>
        ))}
        onFocus={onInputFocus}
        onBlur={() => onInputBlur(`${fieldName}_input_timer`)}
        disabled={isValidCEP}
      />
    )
  }

  const clearFields = () => {
    resetField('address')
    resetField('addressNumber')
    resetField('state')
    resetField('city')
  }

  const handleCepBlur = async ({ target: { value }}) => {
    setIsValidCEP(false)
    clearErrors('cep')
    
    startLoading()
    if (!value) {
      setFieldsOrder(DEFAULT_FIELDS_ORDER)
      handleDefaultData()
      stopLoading()
      return
    }

    if (value.length < 9) {
      setFieldsOrder(DEFAULT_FIELDS_ORDER)
      handleDefaultData()
      stopLoading()
      return 
    }

    onInputBlur('cep_input_timer')

    const response = await getCEPData({ cep: value })

    const { 
      ok, 
      data: responseData = {},
      errorDetails = {} 
    } = response

    if(!ok) {
      setFieldsOrder(DEFAULT_FIELDS_ORDER)
      stopLoading()

      let message = translate('address.cep.error.generic')

      if (errorDetails?.response?.status === 404) {
        message = translate('address.cep.error.invalid')
      }
      
      return setError('cep', { message })
    }

    clearFields()

    setValue('address', responseData.street)
    setValue('state', responseData.state)

    const findState = findStateByName(responseData.state)
    const filteredCities = filterCities(findState.id)
    setAvailableCities(filteredCities)
    setValue('city', responseData.city)
    setIsValidCEP(true)

    setFieldsOrder([
      'ADDRESS',
      'ADDRESS_NUMBER',
      'STATE',
      'CITY',
    ])
    
    stopLoading() 
  }

  const handleCompleteStep = () => {
    const actualTime = new Date()

    registerTime(
      'third_registration_step',
      actualTime.getTime() - stepTimer.getTime()
    )
    onCompleteStep([
      'tandc',
      'address',
      'city',
      'state',
      'country',
      'mobilePrefix',
      'mobileNumber',
    ])
  }

  const handleCepChange = ({ target: { value }}) => {
    setCepValue(value)
    handleCepBlur({ target: { value }})
  }

  const fieldsToWatch = [
    'address',
    'city',
    'state',
  ]

  const FIELDS = {
    ADDRESS: <Field
              label={translate('userProfile.address')}
              autoCompleteOff
              showErrorMsg
              errors={errors}
              onFocus={onInputFocus}
              onChange={(e) => {
                clearResponseError()
                clearErrors('address')
                setValue('address', e?.target?.value)
              }}
              onBlur={() => onInputBlur('address_input_timer')}
              formProperties={register('address', {
                required: true,
              })}
            />,
    ADDRESS_NUMBER: <Field
                      label={translate('register.addressNumber')}
                      autoCompleteOff
                      showErrorMsg
                      errors={errors}
                      onFocus={onInputFocus}
                      onBlur={() => onInputBlur('addressNumber_input_timer')}
                      formProperties={register('addressNumber', {
                        required: false,
                      })}
                    />,
    STATE: createDropdown({ values: regions, fieldName: 'state', onChange: handleStateChange }),
    CITY: createDropdown({ values: availableCities, fieldName: 'city'})
  }

  return (
    <StyledWrapper>
      <Text bold>
        {translate('address.cep.enterCep')}
      </Text>
      <Field
        label={translate('register.cep')}
        type="tel"
        autoCompleteOff
        mask="99999-999"
        showErrorMsg
        errors={errors}
        onFocus={onInputFocus}
        // onBlur={handleCepBlur}
        onChange={handleCepChange}
        hideSymbols={cepValue.length < 9}
        formProperties={register('cep')}
      />

      <Divider />

      <If 
        condition={isLoading}
        render={() => (
          <StyledLoaderWrapper>
            <SpinnerLoader theme="accentPrimary" size="md" />
          </StyledLoaderWrapper>
        )}
        renderElse={() => (
          <>
            <Text bold>
              {translate(
                isValidCEP ? 
                  'address.cep.yourAddress' : 
                  'address.cep.enterAddressManually'
              )}
            </Text>
            <div>
              {fieldsOrder.map((field) => FIELDS[field])}
            </div>
          </>
        )}
      />

      <Turnstile 
        action="REGISTRATION"
        onCaptchaVerified={onCaptchaVerified}
        onCaptchaExpired={onCaptchaExpired}
      />

      <Button
        id="nextBtn1"
        expand
        loading={loading}
        type="button"
        style={{
          fontSize: brandConfig.fonts.text.button.size.desktop,
        }}
        disabled={
          isLoading || 
          loading || 
          formSubmitIsDisabled(fieldsToWatch, getValues, errors) ||
          (!isCaptchaPassed && captchaTurnstileOn)
        }
        onClick={handleCompleteStep}
      >
        {translate('common.register')}
      </Button>
    </StyledWrapper>
  )
}

CepAddressStep.propTypes = {
  formProperties: PropTypes.shape({
    register: PropTypes.func,
    trigger: PropTypes.func,
    getValues: PropTypes.func,
    setError: PropTypes.func,
    setValue: PropTypes.func,
    clearErrors: PropTypes.func,
    resetField: PropTypes.func,
    watch: PropTypes.func,
    formState: PropTypes.shape({ errors: PropTypes.object }),
  }),
  setMobilePrefix: PropTypes.func,
  data: PropTypes.shape({
    mobilePrefix: PropTypes.string,
    phoneCodes: PropTypes.array,
    regions: PropTypes.array,
    cities: PropTypes.array,
  }),
  responseError: PropTypes.shape({
    message: PropTypes.string,
    messageCode: PropTypes.number,
  }),
  clearResponseError: PropTypes.func,
  onCompleteStep: PropTypes.func,
  loading: PropTypes.bool,
  registerTime: PropTypes.func,
  onInputFocus: PropTypes.func,
  onInputBlur: PropTypes.func,
  setToken: PropTypes.func,
  defaultData: PropTypes.shape({
    state: PropTypes.string,
    city: PropTypes.string,
    address: PropTypes.string,
  })
}
