import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from '../../../context/translationProvider'
import { StyledDivider, StyledDividerLabel } from '../divider/styles'

const Divider = (props) => {
  const { altText } = props
  const { translate } = useTranslation()

  return (
    <StyledDivider>
      <StyledDividerLabel>
      {altText || translate('common.or')}
      </StyledDividerLabel>
    </StyledDivider>
  )
}

Divider.propTypes = {
  altText: PropTypes.string,
}

export { Divider }
