import styled from 'styled-components';

export const StyledWrapper = styled.div`
  > p {
    font-size: 25px;
  }
`;

export const StyledLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 350px;
`