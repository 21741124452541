import React from 'react'
import PropTypes from 'prop-types'
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google'
import { EnvConfig } from '../../../config/EnvConfig'
import { StyledText } from './styles'
import GoogleLogo from '../../../images/Google.png'
import { useSocialSignIn } from '../../../hooks/useSocialSignIn'
import { SOCIAL_SIGNIN_TYPES } from '../../../utils/constants'
import { Button } from '../../atoms/button'
import { useEventTracking } from '../../../context/eventTrackingProvider'

export default function GoogleSignIn({ 
  onAuthSuccess, 
  onAuthError, 
  buttonLabel = '', 
  eventName = ''
}) {
  return (
    <GoogleOAuthProvider clientId={EnvConfig.GATSBY_GOOGLE_SSO_CLIENT_ID}>
      <GoogleSignInButton 
        onAuthSuccess={onAuthSuccess} 
        buttonLabel={buttonLabel} 
        onAuthError={onAuthError}
        eventName={eventName}
      />
    </GoogleOAuthProvider>
  )
}

const GoogleSignInButton = ({ onAuthSuccess, onAuthError, buttonLabel, eventName }) => {
  const { socialSignIn } = useSocialSignIn()
  const { trackEvent } = useEventTracking()
  
  const login = useGoogleLogin({
    flow: 'auth-code',           
    scope: 'openid profile email',
    onSuccess: async (tokenResponse) => {
      const response = await socialSignIn({
        code: tokenResponse.code,
        type: SOCIAL_SIGNIN_TYPES.GOOGLE,
        onError: onAuthError
      })

      if (response?.flow === 'REGISTER') {
        onAuthSuccess({
          ...response.data,
          type: SOCIAL_SIGNIN_TYPES.GOOGLE
        })
      }

      if (response?.flow === 'LOGIN') {
        onAuthSuccess({
          action: 'login',
        })
      }
    },
    onError: (err) => {
      console.error('Erro no Google Login (OAuth implícito):', err)
    },
  })

  const googleLogin = () => {
    trackEvent(eventName, {}, true)
    login()
  }

  return (
    <Button expand dark bordered onClick={googleLogin} type="button">
      <StyledText>
        <img src={GoogleLogo} />
        {buttonLabel}
      </StyledText>
    </Button>
  )
}

GoogleSignIn.propTypes = {
  onAuthSuccess: PropTypes.func.isRequired,
  onAuthError: PropTypes.func,
  buttonLabel: PropTypes.string.isRequired,
  eventName:  PropTypes.string,
}

GoogleSignInButton.propTypes = {
  onAuthSuccess: PropTypes.func,
  onAuthError: PropTypes.func,
  buttonLabel: PropTypes.string.isRequired,
  eventName:  PropTypes.string,
}
