import React, { useState, useEffect } from 'react'
import {
  StyledCookieMessageWrapper,
  StyledNoticeText,
  CTAButtonsWrapper,
  StyledButton,
} from './styles'
import { If } from '../../atoms/if'
import { Modal } from '../../atoms/modal'
import useModal from '../../../hooks/useModal'
import useCookie from '../../../hooks/useCookie'
import { useTranslation } from '../../../context/translationProvider'
import { updateAllHrefsIfFbUser } from '../../../helpers/urlHelper'
import CookieSettings from '../cookieSettings'
import { useBannerPosition } from '../../../hooks/useBannerPosition'

const CookiesBanner = () => {
  const { translate } = useTranslation()
  const banner = useBannerPosition()

  const [cookie, updateCookie] = useCookie('caccept', '')
  const [, updatePaCookie] = useCookie('paccept', '')
  const [cookieAccepted, setCookieAccepted] = useState(true)

  const { openModal, closeModal, isOpen } = useModal()

  useEffect(() => {
    setCookieAccepted(Boolean(cookie))
  }, [cookie])

  const handleAcceptCookies = () => {
    updateCookie('on', 365)
    updatePaCookie('on', 365)
    setCookieAccepted(true)

    if (typeof window !== 'undefined' && typeof window.gtag === 'function') {
      window.gtag('consent', 'update', {
        ad_storage: 'granted',
        analytics_storage: 'granted',
      })
    }
  }

  if (cookieAccepted) {
    return null
  }

  const marginBottom = banner.position === 'bottom'
    ? banner.height
    : 0

  return (
    <>
      <StyledCookieMessageWrapper marginBottom={marginBottom}>
        <StyledNoticeText
          dangerouslySetInnerHTML={{
            __html: updateAllHrefsIfFbUser(
              translate('common.cookie.disclamer')
            ),
          }}
        />
        <CTAButtonsWrapper>
          <StyledButton bordered onClick={openModal}>
            {translate('common.cookie.preferences')}
          </StyledButton>
          <StyledButton primary onClick={handleAcceptCookies}>
            {translate('common.cookie.button')}
          </StyledButton>
        </CTAButtonsWrapper>
      </StyledCookieMessageWrapper>
      <If
        condition={isOpen}
        render={() => (
          <Modal
            title={translate('common.cookie.settings')}
            isOpen={isOpen}
            onClose={closeModal}
            zIndex={1000001}
          >
            <CookieSettings
              onClose={closeModal}
              onUpdate={() => setCookieAccepted(true)}
              forceCheck={true}
            />
          </Modal>
        )}
      />
    </>
  )
}

export default CookiesBanner
