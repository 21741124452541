import { EnvConfig } from '../config/EnvConfig';

export const treatLoginErrors = (error, translate) => {
  switch (error.messageCode) {
    case 174:
      return translate(
        'login.error.the_login_failed_please_check_your_username_and_password'
      );
    case 420:
      return translate('login.error.ReCaptcha_verification_failure_code_420');
    case 7:
      return translate('login.error.self_exclusion');
    case 6:
      return translate('login.error.session_limit');
    case 19:
      return translate('login.error.cool_off');
    case 33:
      return translate('login.error.user_login_is_disabled')
        ?.replace(
          '{{SUPPORT_EMAIL}}',
          `${EnvConfig.GATSBY_SUPPORT_EMAIL}` ?? ''
        );
    case 34: {
      if (!error?.data?.lockedUntil) {
        return error.message;
      }

      const date = new Date(error?.data?.lockedUntil + 'Z');

      return translate('login.error.accountLocked').replaceAll(
        '[time]',
        date.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        })
      );
    }
    case 197:
      return translate('account.not-verified');
    case 280:
      return translate('login.error.KYCpepper');
    case 70:
      return translate('login.error.wrongEmail');
    case 27:
      return translate('account.verification.contactSupport');
    case 223:
      return translate('login.error.keycloakTimeout');
    default:
      return error.message;
  }
};