import { removeNationalIdMask, removePhoneMask } from '../../helpers/mask'
import { cookiePrefix, getCookie } from '../cookies'
import { useFeatureValue } from '@growthbook/growthbook-react'
import { getWebsiteUrl } from '../getWebsiteUrl'

export const useRegisterModel = () => {
  const enablePushNotifications = useFeatureValue(
    'enable_push_notifications_selection'
  )

  const getRegisterModel = async (
    recaptchaToken,
    formProperties,
    friendReferrerCode,
    country,
    socialSignInType = null
  ) => {
    const { getValues } = formProperties
    const websiteURL = getWebsiteUrl()

    const tandcChecked = getValues('tandc')
    const privacyPolicyChecked = getValues('privacyPolicy')
    const marketingChecked = getValues('marketing')
    const belongHereChecked = getValues('belongHere')

    const isOrganicOrBranded = getCookie(`${cookiePrefix}googleOrganic`)
    const affiliateToken = getCookie(`${cookiePrefix}affiliateToken`) || null
    const affiliateTokenCreationDate =
      getCookie(`${cookiePrefix}affiliateTokenCreationDate`) || null
    const PPCToken = getCookie(`${cookiePrefix}PPCToken`) || null
    const caToken = getCookie(`${cookiePrefix}caToken`) || null
    const caTokenCreationDate =
      getCookie(`${cookiePrefix}caTokenCreationDate`) || null

    const getUrlToken = () => {
      if (PPCToken)
        return {
          token: PPCToken,
          type: 'myAffiliates',
        }

      if (!!isOrganicOrBranded && isOrganicOrBranded !== 'false')
        return {
          token: isOrganicOrBranded,
          type: 'SEO',
        }

      if (caToken && affiliateToken) {
        if (caTokenCreationDate > affiliateTokenCreationDate) {
          return {
            token: caToken,
            type: 'cleverAdvertising',
          }
        }

        return {
          token: affiliateToken,
          type: 'myAffiliates',
        }
      }

      if (caToken)
        return {
          token: caToken,
          type: 'cleverAdvertising',
        }

      if (affiliateToken)
        return {
          token: affiliateToken,
          type: 'myAffiliates',
        }

      return null
    }

    const url_token = getUrlToken()?.token
    const url_token_type = getUrlToken()?.type

    const model = {
      email: getValues('email').trim().slice(0, 100).toLowerCase(),
      password: getValues('password'),
      languageCode: getValues('languageCode'),
      address: getValues('address').slice(0, 128),
      city: getValues('city').slice(0, 90),
      state: getValues('state').slice(0, 90),
      countryCode: country.code,
      afId: getValues('afId'),
      currencyCode: getValues('currency'),

      mobilePrefix: getValues('mobilePrefix'),
      mobileNumber: removePhoneMask(getValues('mobileNumber')),
      affiliateMarker: getValues('couponCode')?.slice(0, 50),
      affToken: url_token || null,
      tokenType: url_token_type || null,

      acceptNews: marketingChecked,
      acceptTc: tandcChecked,
      acceptPp: privacyPolicyChecked,
      belongHere: belongHereChecked,

      isPushNotificationsAllowed: enablePushNotifications
        ? marketingChecked
        : false,
      activationUrl: `${websiteURL}activate/`,
      token: recaptchaToken,
      referralToken: friendReferrerCode?.replace('/', ''),
      nationalityId: getValues('nationality'),
      nationalId: getValues('national_id')
        ? removeNationalIdMask(getValues('national_id'))
        : null,
      socialSignInToken: getValues('idToken'),
      socialSignInType,
    }

    if (!model.afId) {
      model.fullName = getValues('name').slice(0, 150)
    }

    return model
  }

  return { getRegisterModel }
}
