import { apiGET, apiPOST, apiPUT } from '../utils/adapterFunctions'
import { coreApi } from './base'

export const getUserLimits = async () => {
  try {
    const { data } = await coreApi.get('/limit')
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getUserScheduledLimits = async (id) => {
  try {
    const { data } = await coreApi.get(`/limit/${id}/scheduled-actions`)
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const resendActivationEmail = async (email, url) => {
  try {
    const { data } = await coreApi.get(
      `/auth/activate/${encodeURIComponent(email)}?activation_url=${url}`
    )
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getSportsbookToken = async () => {
  try {
    const { data } = await coreApi.get('/sportsbook/v2/altenar/token')
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getSportsbookTokenV2 = async () => {
  try {
    const { data } = await coreApi.get('/sportsbook/v2/altenar/token/v2')
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getAuthKambiUser = async () => {
  try {
    const { data } = await coreApi.get('/sportsbook/token')
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getCasinoToken = async () => {
  try {
    const { data } = await coreApi.get('/casino/oryx/token')
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getUserWallet = async () => {
  try {
    const { data } = await coreApi.get('/wallet')
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getUserLimitPeriods = async () => {
  try {
    const { data } = await coreApi.get('/limit/period')
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getUserMaximumLimit = async () => {
  try {
    const { data } = await coreApi.get('/limit/maximum-days')
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const setUserLimit = async (model) => {
  try {
    const { data } = await coreApi.post('/limit', model)
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const setUserUpdateLimit = async (model) => {
  try {
    const { data } = await coreApi.put(`/limit/${model.id}`, model)
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const updateAccount = async (model) => {
  try {
    const { data } = await coreApi.put('/user', model)
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getUserRequiredData = async (model) => {
  return apiGET('/user/required-data', model)
}

export const updateAddressAndNumber = async (model) => {
  try {
    const { data } = await coreApi.put(
      '/user/update-address-and-mobile-number',
      model
    )
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const updatePlayerConsent = async (model) => {
  try {
    const { data } = await coreApi.patch('/user', model)
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const updateNationalId = async (model) => {
  try {
    const { data } = await coreApi.patch('/user/update-national-id', model)
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const requestEmailChange = async (email, redirectionUrl) => {
  try {
    const { data } = await coreApi.put('/auth/email', { email, redirectionUrl })
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const updatePassword = async (model) => {
  try {
    const { data } = await coreApi.put('/auth/password', model)
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const cashoutVault = async () => {
  try {
    const response = await coreApi.post('/player-rewards/claim', {})
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getUserNotification = async () => {
  try {
    const { data } = await coreApi.get('/user/user-notification')
    return { ok: true, data }
  } catch (error) {
    console.warn(error)
    return { ok: false, error: error.response.data }
  }
}

export const setVisualizeNotification = async () => {
  try {
    const { data } = await coreApi.post('/user/visualize-notification')
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getUserCapabilitiesAndNationalIdStatus = async () => {
  try {
    const { data } = await coreApi.get('/user/capabilities')
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const acceptTerms = async (model) => {
  try {
    const { data } = await coreApi.post('/user/tc', model)
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const acceptPP = async () => {
  return await apiPUT('/user/accept/pp')
}

export const getMostRecentPlayedGames = async () => {
  try {
    const { data } = await coreApi.get('/casino/recently-played')
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getUserActiveVault = async () => {
  try {
    const { data } = await coreApi.get('/player-rewards/active')
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getUserClaimedVault = async ({ pageNumber }) => {
  try {
    const response = await coreApi.get('/player-rewards/claimed', {
      params: {
        pageNumber,
        pageSize: 6,
      },
    })
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getUserPopups = async (language) => {
  try {
    const {
      data: { data },
    } = await coreApi.get('/popups', { params: { language } })

    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error?.response?.data }
  }
}

export const checkUserPopup = async (popupId, status) => {
  try {
    const { data } = await coreApi.patch(`/popups/${popupId}`, {
      status,
    })

    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error?.response?.data }
  }
}

export const getUserIntercomToken = async () => {
  return await apiPOST('/intercom/token', null, {
    hasNoData: false,
  })
}

export const getSmarticoHash = async () => {
  return await coreApi.get('/smartico/players/hash')
}

export const requestUserActivityReport = async (startingDate, endingDate) => {
  return await coreApi.post('/comprehensive-user-activity-report', {
    startingDate,
    endingDate,
  })
}

export const checkUserActivityReport = async (startingDate, endingDate) => {
  return await coreApi.get(
    '/comprehensive-user-activity-report/verify-data-in-range',
    {
      params: {
        startingDate,
        endingDate,
      },
    }
  )
}

export const getUserActivityReportRequests = async () => {
  return await coreApi.get('/comprehensive-user-activity-report/tracking')
}

export const getUserActivityReportFile = async (requestId) => {
  return await coreApi.get(
    `/comprehensive-user-activity-report/${requestId}/download`
  )
}
export const getRegisteredBankAccounts = async () => {
  try {
    const {
      data: { data },
    } = await coreApi.get('/player/bank-accounts')

    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error?.response?.data }
  }
}
export const autoWithdrawalOptIn = async () => {
  try {
    return await coreApi.post('/player/auto-withdrawal/opt-in', {})
  } catch (error) {
    return { ok: false, error: error?.response?.data }
  }
}

export const autoWithdrawalOptOut = async () => {
  try {
    return await coreApi.post('/player/auto-withdrawal/opt-out', {})
  } catch (error) {
    return { ok: false, error: error?.response?.data }
  }
}

export const getAutoWithdrawalStatus = async () => {
  try {
    return await coreApi.get('/player/auto-withdrawal')
  } catch (error) {
    return { ok: false, error: error?.response?.data }
  }
}

export const sendUserLocation = async (location) => {
  return await apiPOST('/geo', location)
}

export const getUserSignInMethod = async () => {
  return apiGET('/user/social-sign-in/active')
}

export const untieSocialSignIn = async (password, confirmPassword) => {
  return await apiPUT('/user/social-sign-in/untie', {
    password,
    confirm_password: confirmPassword,
  })
}